// src/env.mjs
import { createEnv } from "@t3-oss/env-nextjs";
import { z } from "zod";

export const env = createEnv({
	/*
	 * Serverside Environment variables, not available on the client.
	 * Will throw if you access these variables on the client.
	 */
	server: {
		GRAPHQL_URL: z.string().url(),
		NODE_ENV: z.enum(["development", "beta", "staging", "production"]),
		NEXTAUTH_SECRET:
			process.env.NODE_ENV === "production"
				? z.string().min(1)
				: z.string().min(1).optional(),
		NEXTAUTH_URL: z.string().min(1),
		EMAIL_SERVER_USER: z.string().min(1),
		EMAIL_SERVER_PASSWORD: z.string().min(1),
		EMAIL_SERVER_HOST: z.string().min(1),
		EMAIL_SERVER_PORT: z.string(),
		EMAIL_FROM: z.string().min(1),
		COGNITO_CLIENT_ID: z.string().min(1),
		COGNITO_CLIENT_SECRET: z.string().min(1),
		COGNITO_USER_POOL: z.string().min(1),
		TABLE_NAME: z.string().min(1),
		GOOGLE_CLIENT_ID: z.string().min(1),
		GOOGLE_CLIENT_SECRET: z.string().min(1),
		APPLE_CLIENT_ID: z.string().min(1),
		APPLE_CLIENT_SECRET: z.string().min(1),
		API_KEY: z.string().min(1),
		SENTRY_DSN: z.string().min(1),
		SENTRY_AUTH_TOKEN: z.string().min(1),
		ENV_NAME: z.string().min(1),
	},
	/*
	 * Environment variables available on the client (and server).
	 *
	 * 💡 You'll get type errors if these are not prefixed with NEXT_PUBLIC_.
	 */
	client: {
		NEXT_PUBLIC_CLOUDFRONT_URL: z.string().url(),
		NEXT_PUBLIC_PAYPAL_CLIENT_ID: z.string(),
		NEXT_PUBLIC_PLAN_ID_PROMOTER: z.string(),
		NEXT_PUBLIC_PLAN_ID_SKILLS: z.string(),
		NEXT_PUBLIC_PLAN_ID_SKILLS_PLUS: z.string(),
		NEXT_PUBLIC_APP_INSTANCE_ARN: z.string(),
		NEXT_PUBLIC_APP_DOMAIN: z.string().url(),
		NEXT_PUBLIC_SENTRY_DSN: z.string().min(1),
		NEXT_PUBLIC_MIXPANEL_TOKEN: z.string().min(1),
		NEXT_PUBLIC_SEARCH_SIZE: z.string(),
		NEXT_PUBLIC_PAGE_SIZE: z.string()
	},
	/*
	 * Due to how Next.js bundles environment variables on Edge and Client,
	 * we need to manually destructure them to make sure all are included in bundle.
	 *
	 * 💡 You'll get type errors if not all variables from `server` & `client` are included here.
	 */
	runtimeEnv: {
		GRAPHQL_URL: process.env.GRAPHQL_URL,
		NODE_ENV: process.env.NODE_ENV,
		NEXTAUTH_SECRET: process.env.NEXTAUTH_SECRET,
		NEXTAUTH_URL: process.env.NEXTAUTH_URL,
		NEXT_PUBLIC_CLOUDFRONT_URL: process.env.NEXT_PUBLIC_CLOUDFRONT_URL,
		NEXT_PUBLIC_PAYPAL_CLIENT_ID: process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID,
		NEXT_PUBLIC_PLAN_ID_PROMOTER: process.env.NEXT_PUBLIC_PLAN_ID_PROMOTER,
		NEXT_PUBLIC_PLAN_ID_SKILLS: process.env.NEXT_PUBLIC_PLAN_ID_SKILLS,
		NEXT_PUBLIC_PLAN_ID_SKILLS_PLUS:
			process.env.NEXT_PUBLIC_PLAN_ID_SKILLS_PLUS,
		EMAIL_SERVER_USER: process.env.EMAIL_SERVER_USER,
		EMAIL_SERVER_PASSWORD: process.env.EMAIL_SERVER_PASSWORD,
		EMAIL_SERVER_HOST: process.env.EMAIL_SERVER_HOST,
		EMAIL_SERVER_PORT: process.env.EMAIL_SERVER_PORT,
		EMAIL_FROM: process.env.EMAIL_FROM,
		COGNITO_CLIENT_ID: process.env.COGNITO_CLIENT_ID,
		COGNITO_CLIENT_SECRET: process.env.COGNITO_CLIENT_SECRET,
		COGNITO_USER_POOL: process.env.COGNITO_USER_POOL,
		TABLE_NAME: process.env.TABLE_NAME,
		GOOGLE_CLIENT_ID: process.env.GOOGLE_CLIENT_ID,
		GOOGLE_CLIENT_SECRET: process.env.GOOGLE_CLIENT_SECRET,
		APPLE_CLIENT_ID: process.env.APPLE_CLIENT_ID,
		APPLE_CLIENT_SECRET: process.env.APPLE_CLIENT_SECRET,
		NEXT_PUBLIC_APP_INSTANCE_ARN: process.env.NEXT_PUBLIC_APP_INSTANCE_ARN,
		API_KEY: process.env.API_KEY,
		SENTRY_DSN: process.env.SENTRY_DSN,
		SENTRY_AUTH_TOKEN: process.env.SENTRY_AUTH_TOKEN,
		NEXT_PUBLIC_APP_DOMAIN: process.env.NEXT_PUBLIC_APP_DOMAIN,
		NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
		ENV_NAME: process.env.ENV_NAME,
		NEXT_PUBLIC_MIXPANEL_TOKEN: process.env.NEXT_PUBLIC_MIXPANEL_TOKEN,
		NEXT_PUBLIC_SEARCH_SIZE: process.env.NEXT_PUBLIC_SEARCH_SIZE,
		NEXT_PUBLIC_PAGE_SIZE: process.env.NEXT_PUBLIC_PAGE_SIZE
	},
});
